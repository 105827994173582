@import 'colors';
@import 'theme/palette';
@import 'theme/base';

table.table {
    width: 100%;
    background-color: transparent;
    border: 1px solid mat-color($primary);

    .mat-mdc-icon-button:hover {
        color: mat-color($primary, 100);
    }

    .table__cell {
        padding: 10px;
        vertical-align: middle;
        border-right: 1px solid mat-color($primary);
        border-bottom: 1px solid mat-color($primary);

        &:first-child {
            padding-left: 10px;
        }

        &:last-child {
            padding-right: 10px;
        }

        &-link {
            padding: 0 0 1px 0;
            line-height: 18px;
            color: mat-color($primary, 100);
            text-decoration: none;
            border-bottom: 1px solid mat-color($primary, 600);

            &:hover {
                transition: 0.3s ease;
                border-bottom: 1px solid mat-color($primary, 100);
            }

            &--disabled {
                color: mat-color($primary);
            }

            &--warning {
                color: $alert_yellow;
            }

            &--danger {
                color: $red;
            }
        }

        &-user {
            &-link {
                display: inline-grid;
                grid-template-columns: auto 1fr;
                grid-gap: 0 10px;
                align-items: center;
                justify-items: start;
                word-break: keep-all;
                white-space: nowrap;
                color: mat-color($primary, 100);
                text-decoration: none;

                &:hover {
                    transition: 0.3s ease;

                    :nth-child(2),
                    :nth-child(3) {
                        border-bottom: 1px solid mat-color($primary, 100);
                    }
                }

                :first-child {
                    display: block;
                    grid-row: 1 / 3;
                    width: 40px;
                    height: 40px;
                }

                :nth-child(2),
                :nth-child(3) {
                    padding: 0 0 1px 0;
                    border-bottom: 1px solid mat-color($primary, 600);
                }

                :last-child {
                    grid-column: 2 / -1;
                }
            }
        }

        &--narrow {
            width: 60px;
        }

        &--middle {
            width: 110px;
        }

        &--wide {
            width: 150px;
        }

        &--extra-wide {
            width: 180px;
        }

        &--large {
            width: 240px;
        }

        &--extra-large {
            width: 350px;
        }

        &--centered {
            text-align: center;
            justify-content: center;

            .mat-sort-header-container {
                justify-content: center;
            }
        }

        &--unbordered {
            border-right-color: transparent;
        }

        &--unbordered-footer {
            border-right: none;
        }

        &--danger {
            background-color: rgba($red, 0.2);
        }

        &--right {
            text-align: right;
            justify-content: flex-end;
        }
    }

    .table__row {
        &-header,
        &-footer {
            background-color: mat-color($primary, 600);

            .table__cell {
                background-color: mat-color($primary, 600);
            }
        }

        &-header {
            .table__cell {
                top: 55px !important; //for position: sticky
                z-index: 2 !important;
            }
        }

        &:nth-child(odd) {
            background-color: mat-color($primary, 800);
        }

        &:nth-child(even) {
            background-color: mat-color($primary, 900);
        }
    }
}

.table__empty {
    border: 1px dashed mat-color($primary, 500);
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    color: mat-color($primary, 500);
    width: 100%;
}

@media print {
    table.table {
        color: $black;
        border: 1px solid $black;
        border-collapse: collapse;

        .table__cell {
            padding: 5px 10px;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            &-link {
                padding: 0;
                color: $black;
                border-bottom: none;

                &--disabled,
                &--warning,
                &--danger {
                    color: $black;
                }
            }

            &--danger {
                background-color: transparent;
            }
        }

        .table__row {
            &-header,
            &-footer {
                color: $black;
                background-color: transparent;

                .table__cell {
                    color: $black;
                    background-color: transparent;
                }
            }

            &:nth-child(odd),
            &:nth-child(even) {
                color: $black;
                background-color: transparent;
            }
        }
    }
}

// create after ng 17
.data-table {
    --mat-table-background-color: #{mat-color($primary, 900)};

    box-shadow: none;

    th,
    mat-header-cell,
    td,
    mat-cell {
        padding-top: 4px;
        padding-bottom: 4px;
        vertical-align: middle;
        align-items: center;

        &[justify='left'] {
            text-align: left;
            justify-content: flex-start;

            .mat-sort-header-container {
                text-align: left;
                justify-content: flex-start;
            }

            .mat-sort-header-content {
                text-align: left;
            }
        }

        &[justify='center'] {
            text-align: center;
            justify-content: center;

            .mat-sort-header-container {
                text-align: center;
                justify-content: center;
            }

            .mat-sort-header-content {
                text-align: center;
            }
        }

        &[justify='right'] {
            text-align: right;
            justify-content: flex-end;

            .mat-sort-header-container {
                text-align: right;
                justify-content: flex-end;
            }

            .mat-sort-header-content {
                text-align: right;
            }
        }
    }

    th,
    mat-header-cell {
        @extend .body-3-bold !optional;
    }

    thead tr,
    mat-header-row {
        background: mat-color($primary, 600);
        box-shadow: 0px -1px 0px 0px rgba($black, 0.12);
    }

    tfoot tr,
    mat-footer-row {
        background: mat-color($primary, 700);
        box-shadow: 0px -1px 0px 0px rgba($black, 0.12);
    }

    &-header {
        &-compress {
            width: 0px;
        }

        &-expand {
            width: 100%;
        }
    }

    &-cell-nowrap {
        white-space: nowrap;
    }

    &-row-sticky-page {
        position: sticky;
        top: 60px;
        z-index: 100;
    }
}

.table-paginator {
    position: sticky;
    bottom: 0;
    z-index: 1;
}
