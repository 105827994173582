@use '@angular/material' as mat;
@import 'theme/palette';

.mat-button-toggle {
    &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        color: mat-color($primary, A200-contrast);
        background-color: mat-color($primary, A200);
    }
}

.mat-button-toggle {
    &-medium {
        @include mat.button-toggle-density(-1);
    }

    &-middle {
        @include mat.button-toggle-density(-2);
    }

    &-small {
        @include mat.button-toggle-density(-3);
    }

    &-little {
        @include mat.button-toggle-density(-4);
    }
}
