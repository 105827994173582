@use '@angular/material' as mat;

.mat-checkbox {
    &-medium {
        @include mat.checkbox-density(-1);
    }

    &-middle {
        @include mat.checkbox-density(-2);
    }

    &-small {
        @include mat.checkbox-density(-3);
    }

    &-little {
        --mdc-checkbox-state-layer-size: 24px;
        --mat-checkbox-touch-target-display: none;
    }
}

.mdc-checkbox {
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
}
