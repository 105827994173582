.cdk-overlay-pane:has(.mat-select-expand) {
    width: auto !important;
}

div.mat-mdc-select-panel.autocomplete-search {
    padding: 0 0 8px;
}

div.mat-mdc-select-panel.chat-search {
    padding: 0 0 8px;

    .mdc-list-item__primary-text {
        opacity: 1;
    }
}
