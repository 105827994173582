@use '@angular/material' as mat;

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
}

.mat-icon.mat-icon {
    &-large {
        height: 40px;
        width: 40px;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        &.mat-icon-inline {
            font-size: 40px;
        }
    }

    &-medium {
        height: 36px;
        width: 36px;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        &.mat-icon-inline {
            font-size: 36px;
        }
    }

    &-small {
        height: 20px;
        width: 20px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        &.mat-icon-inline {
            font-size: 20px;
        }
    }

    &-little {
        height: 16px;
        width: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        &.mat-icon-inline {
            font-size: 16px;
        }
    }

    &-tiny {
        height: 12px;
        width: 12px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        &.mat-icon-inline {
            font-size: 12px;
        }
    }
}
