@import 'theme/palette.scss';
@import 'styles/colors.scss';

$indent-steps: 8;
$indent-base: 20px;
$li-indent-base: 10px;

quill-editor,
quill-view-html {
    white-space: pre-line;
    word-break: break-word;

    .ql-container {
        color: $white;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;

        .ql-editor {
            strong {
                font-weight: 600;
            }

            a {
                color: $blue_light;
                cursor: pointer;
                transition: color 0.15s ease;

                &:hover {
                    color: $link_hover;
                }
            }

            em {
                font-style: italic;
            }

            u {
                text-decoration: none;
                border-radius: 3px;
                padding: 0 4px;
                color: mat-color($primary, 900);
                background-color: mat-color($primary, 400);
            }

            h1 {
                font-size: 20px;
                line-height: 26px;
                font-weight: 600;
            }

            h2 {
                font-size: 18px;
                line-height: 20px;
                font-weight: 600;
            }

            h3 {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
            }

            ol,
            ul {
                &:not(:first-child) {
                    margin-top: 5px;
                }

                li {
                    &:not(:first-child) {
                        margin-top: 5px;
                    }
                }
            }

            ol {
                padding: 0;

                li {
                    padding: 0 0 0 20px;
                }
            }

            ul {
                padding: 0 0 0 20px;

                li {
                    position: relative;
                    padding: 0;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        margin: 7px 0 0 -10px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: mat-color($primary, 500);
                    }
                }
            }

            @for $i from 1 through $indent-steps {
                .ql-indent-#{$i}:not(.ql-direction-rtl) {
                    padding-left: $indent-base * $i + 20;
                }

                li.ql-indent-#{$i}:not(.ql-direction-rtl) {
                    padding-left: $i * 20px;
                }
            }

            pre.ql-syntax {
                background-color: mat-color($primary, 900);
                color: mat-color($primary, 400);
                font-family: monospace;
                padding: 15px;
                margin: 15px 0 0;
                border-radius: 6px;
            }

            blockquote {
                display: flex;
                border-left: none;
                padding: 0 0 0 10px;
                margin: 0;

                &:not(:first-child) {
                    margin-top: 15px;
                }

                &::before {
                    content: '';
                    display: block;
                    flex-shrink: 0;
                    margin-right: 10px;
                    height: auto;
                    width: 6px;
                    background-color: mat-color($primary, 600);
                    border-radius: 4px;
                }
            }

            code,
            i.ql-highlight {
                display: inline-block;
                padding: 1px 4px 0;
                border-radius: 3px;
                background-color: mat-color($primary, 400);
                color: mat-color($primary, 900);
                font-size: 15px;
                line-height: 22px;
                font-style: normal;
                font-family: monospace;
                font-weight: 400;

                strong {
                    font-weight: 400;
                }
            }

            code {
                i.ql-highlight {
                    padding: 0;
                    background: transparent;
                }
            }
        }
    }
}

quill-editor {
    display: block;

    .ql-toolbar {
        border-radius: 4px 4px 0 0;

        &.ql-snow {
            border-color: mat-color($primary, 600);
        }

        .ql-formats {
            button {
                transition: 0.15s ease;

                &:hover,
                &.ql-active {
                    .ql-stroke,
                    .ql-stroke-miter {
                        stroke: mat-color($primary, 600);
                    }

                    .ql-fill {
                        fill: mat-color($primary, 600);
                    }
                }

                .ql-stroke,
                .ql-stroke-miter {
                    stroke: mat-color($primary, 400);
                }

                .ql-fill {
                    fill: mat-color($primary, 400);
                }
            }

            .ql-picker {
                &-label {
                    color: mat-color($primary, 400);
                    transition: color 0.15s ease;

                    &:hover,
                    &.ql-active {
                        color: mat-color($primary, 600);

                        .ql-stroke {
                            stroke: mat-color($primary, 600);
                        }

                        .ql-color-label {
                            fill: mat-color($primary, 600);
                        }
                    }
                }

                &-item {
                    color: mat-color($primary, 900);
                    transition: color 0.15s ease;

                    &:hover {
                        color: mat-color($primary, 600);
                    }
                }

                .ql-stroke {
                    stroke: mat-color($primary, 400);
                }
            }

            .ql-highlight {
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: url('../../../assets/icon/incode.svg') no-repeat
                        center center;
                }
            }

            .ql-button {
                position: relative;
                background: none;

                &::before {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    margin: 0 auto;
                    background: url('../../../assets/icon/btn.svg') no-repeat
                        center center;
                }
            }

            .ql-header {
                color: mat-color($primary, 400);

                &:hover,
                &.ql-active {
                    color: mat-color($primary, 600);
                }
            }
        }
    }

    .ql-container {
        border-radius: 0 0 4px 4px;

        &.ql-snow {
            border-color: mat-color($primary, 600);
        }

        .ql-tooltip {
            background-color: mat-color($primary, 800);
            border: 1px solid mat-color($primary, 600);
            box-shadow: 0px 0px 5px mat-color($primary, 900);
            border-radius: 3px;
            color: mat-color($primary, 100);
            left: 10px !important;

            input[type='text'] {
                color: mat-color($primary, 100);
                display: inline-block;
                border: 1px solid mat-color($primary, 600);
                font-size: 13px;
                height: 26px;
                padding: 3px 5px;
                width: 170px;
                background: mat-color($primary, 800);
                border-radius: 3px;
            }

            .ql-action,
            .ql-remove {
                color: mat-color($primary, 400);
            }
        }

        .ql-blank::before {
            color: mat-color($primary, 400);
            font-style: normal;
        }
    }

    // only for workbooks
    // after review all quill styles move to all styles
    &.margins {
        .ql-container .ql-editor {
            h1 {
                &:not(:first-child) {
                    margin-top: 35px;
                }
            }

            h2 {
                &:not(:first-child) {
                    margin-top: 30px;
                }
            }

            h3 {
                &:not(:first-child) {
                    margin-top: 25px;
                }
            }

            p:not(:first-child) {
                margin-top: 15px;
            }
        }
    }
}

quill-view-html {
    .ql-container {
        .ql-editor {
            padding: 0;

            h1 {
                &:not(:first-child) {
                    margin-top: 35px;
                }
            }

            h2 {
                &:not(:first-child) {
                    margin-top: 30px;
                }
            }

            h3 {
                &:not(:first-child) {
                    margin-top: 25px;
                }
            }

            p:not(:first-child) {
                margin-top: 15px;
            }
        }
    }
}
