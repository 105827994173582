@import 'theme/palette';

.navigation-link,
.link {
    color: mat-color($active, A200);
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
        color: mat-color($active, A400);
        text-decoration: none;
    }

    &:has(.mat-icon) {
        display: inline-flex;
        gap: 10px;

        .mat-icon {
            flex-shrink: 0;
        }
    }
}
