.mat-headline-1,
.mat-typography .mat-headline-1,
.mat-headline-2,
.mat-typography .mat-headline-2,
.mat-headline-3,
.mat-typography .mat-headline-3,
.mat-headline-4,
.mat-typography .mat-headline-4,
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1,
.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2,
.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3,
.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4,
.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5,
.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6,
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
    margin: 0;
}

.body-1-bold,
.mat-typography .body-1-bold,
.mat-typography h4.body-1-bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
}

.body-2-bold,
.mat-typography .body-2-bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.body-3,
.mat-typography .body-3 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.25px;
}

.body-3-bold,
.mat-typography .body-3-bold {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.25px;
}

.caption-bold,
.mat-typography .caption-bold {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
}

.mat-overline {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
