@import 'theme/palette';
@import 'material/mdc-base';
@import 'styles/base';
@import 'styles/table';
@import 'styles/quill';

// TODO: check style: create separate file
* {
    box-sizing: border-box;
}

body {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: 1280px;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 0;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: mat-color($primary);
    }
}

app-root,
app-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

// TODO: check style: ?
.tabs {
    margin-top: 40px;
    &__item:hover {
        color: mat-color($primary, 400);
    }
}

.mat-input-element[type='time'] {
    &::after {
        display: none;
    }
}

.layout {
    .invalid-cell {
        background-color: mat-color($warn, 300);
    }

    .invalid-input.mat-mdc-input-element {
        border-bottom: 1px solid mat-color($warn);

        &::-webkit-input-placeholder {
            color: mat-color($warn);
        }
        &::-moz-placeholder {
            color: mat-color($warn);
        }
        &:-ms-input-placeholder {
            color: mat-color($warn);
        }
        &:-moz-placeholder {
            color: mat-color($warn);
        }
    }
}

// TODO: check style: ?
.ngx-charts-outer {
    .ngx-charts {
        .line-chart {
            .tick {
                fill: mat-color($primary, 400);
            }
            .gridline-path {
                stroke: mat-color($primary);
            }
        }
    }

    .chart-legend .legend-label:hover {
        color: mat-color($primary, 100) !important;
    }
}

// TODO: check style: ?
.achievement-dialog {
    .mat-mdc-dialog-container {
        padding: 0;
        border-radius: 12px;
    }
}

// TODO: check style: ?
.panel-dialog {
    .mat-mdc-dialog-container {
        padding: 0;
        border-radius: 12px;
    }

    &--emoji {
        .mat-dialog-container {
            border-radius: 5px;
        }
    }
}

// TODO: check style: ?
.mat-dialog--alt-paddings {
    .mat-mdc-dialog-container {
        padding: 0 24px;
    }

    .mat-mdc-dialog-content {
        padding: 24px;
    }
}

// TODO: check style: ?
.link {
    color: mat-color($primary, 100);
}
