@import 'theme/palette';

.mdc-snackbar.alert {
    &--success {
        .mdc-snackbar__surface {
            background: mat-color($accent, 500);
        }
    }

    &--error {
        .mdc-snackbar__surface {
            background: mat-color($warn-palette, A400);

            .mdc-snackbar__label {
                color: mat-color($primary, 100);
            }
        }
    }
}

.mdc-snackbar.workbook-theory-announce {
    .mdc-snackbar__surface {
        background-color: mat-color($warn, A200);
        border-radius: 34px;
        min-width: auto;
    }

    .mdc-snackbar__label {
        padding: 8px 16px;
    }
}
