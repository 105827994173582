@use '@angular/material' as mat;
@import 'theme/palette';

.mdc-button {
    min-width: 36px !important;
}

.mat-button {
    &-round {
        --mdc-protected-button-container-shape: 36px;
        --mdc-filled-button-container-shape: 36px;
        --mdc-text-button-container-shape: 36px;
        --mdc-outlined-button-container-shape: 36px;
    }

    &-attention:not([color]) {
        --mdc-text-button-label-text-color: #{mat-color($attention, A200)};
        --mat-text-button-state-layer-color: #{mat-color($attention, A200)};
        --mat-text-button-ripple-color: rgba(
            #{mat-color($attention, A200)},
            0.1
        );

        &.mat-mdc-raised-button {
            --mdc-protected-button-container-color: #{mat-color(
                    $attention,
                    A200
                )};
        }

        &.mat-mdc-outlined-button {
            --mdc-outlined-button-label-text-color: #{mat-color(
                    $attention,
                    A200
                )};
            --mat-outlined-button-state-layer-color: #{mat-color(
                    $attention,
                    A200
                )};
        }

        &.mat-mdc-unelevated-button {
            --mdc-filled-button-container-color: #{mat-color($attention, A200)};
        }
    }

    &-medium {
        @include mat.button-density(-1);

        min-width: 32px !important;

        .mat-button-round {
            --mdc-protected-button-container-shape: 32px;
            --mdc-filled-button-container-shape: 32px;
            --mdc-text-button-container-shape: 32px;
            --mdc-outlined-button-container-shape: 32px;
        }
    }

    &-middle {
        @include mat.button-density(-2);

        min-width: 28px !important;

        .mat-button-round {
            --mdc-protected-button-container-shape: 28px;
            --mdc-filled-button-container-shape: 28px;
            --mdc-text-button-container-shape: 28px;
            --mdc-outlined-button-container-shape: 28px;
        }
    }

    &-small {
        @include mat.button-density(-3);

        min-width: 24px !important;

        .mat-button-round {
            --mdc-protected-button-container-shape: 24px;
            --mdc-filled-button-container-shape: 24px;
            --mdc-text-button-container-shape: 24px;
            --mdc-outlined-button-container-shape: 24px;
        }
    }

    &-little {
        --mdc-text-button-container-height: 20px;
        --mdc-filled-button-container-height: 20px;
        --mdc-outlined-button-container-height: 20px;
        --mdc-protected-button-container-height: 20px;
        --mat-text-button-touch-target-display: none;
        --mat-filled-button-touch-target-display: none;
        --mat-protected-button-touch-target-display: none;
        --mat-outlined-button-touch-target-display: none;

        min-width: 20px !important;

        .mat-button-round {
            --mdc-protected-button-container-shape: 20px;
            --mdc-filled-button-container-shape: 20px;
            --mdc-text-button-container-shape: 20px;
            --mdc-outlined-button-container-shape: 20px;
        }
    }
}

.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: #{mat-color($primary, 600-contrast)};
    --mat-text-button-state-layer-color: #{mat-color($primary, 600)};
    --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: #{mat-color($primary, 600)};
    --mdc-protected-button-label-text-color: #{mat-color($primary, 600-contrast)};
    --mat-protected-button-state-layer-color: #{mat-color(
            $primary,
            600-contrast
        )};
    --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
    --mdc-outlined-button-label-text-color: #{mat-color($primary, 600-contrast)};
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #{mat-color($primary, 600)};
    --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #{mat-color($primary, 600)};
    --mdc-filled-button-label-text-color: #{mat-color($primary, 600-contrast)};
    --mat-filled-button-state-layer-color: #{mat-color($primary, 600-contrast)};
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button:has(
        .material-icons,
        .mat-icon,
        mat-icon,
        [matButtonIcon]
    ),
.mat-mdc-raised-button:has(
        .material-icons,
        .mat-icon,
        mat-icon,
        [matButtonIcon]
    ),
.mat-mdc-unelevated-button:has(
        .material-icons,
        .mat-icon,
        mat-icon,
        [matButtonIcon]
    ),
.mat-mdc-button:has(.material-icons, .mat-icon, mat-icon, [matButtonIcon]) {
    &:has(.mdc-button__label:empty) {
        padding: 0;

        > .mat-icon {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 48px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: 12px;
}
