@use '@angular/material' as mat;

.mat-toggle {
    &-medium {
        @include mat.slide-toggle-density(-1);
    }

    &-middle {
        @include mat.slide-toggle-density(-2);
    }

    &-small {
        @include mat.slide-toggle-density(-3);
    }

    &-little {
        --mdc-switch-state-layer-size: 24px;
    }
}
