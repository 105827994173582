@import 'theme/palette';

.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        border-radius: 12px;
    }

    .mat-mdc-dialog-title {
        padding: 16px;
        border-bottom: 1px solid mat-color($primary, 700);

        + .mat-mdc-dialog-content.mdc-dialog__content {
            padding-top: 20px;
        }

        &:before {
            display: none;
        }
    }

    .mat-mdc-dialog-content {
        padding: 20px 24px;
    }

    .mdc-dialog__actions {
        padding: 12px;
        border-top: 1px solid mat-color($primary, 700);

        &:not([align]) {
            justify-content: flex-end;
        }
    }
}

.dialog-layout {
    .mat-mdc-dialog-container {
        padding: 0;
        border-radius: 12px;
        box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2);
    }

    .mat-mdc-dialog-content {
        margin: 0;
        padding: 0;
    }
}

.header-filter-dialog {
    .mat-mdc-dialog-container {
        .mat-mdc-dialog-title {
            + .mat-mdc-dialog-content.mdc-dialog__content {
                padding-top: 16px;
            }
        }

        .mat-mdc-dialog-content {
            padding: 16px;
        }

        .mdc-dialog__actions {
            padding: 0 12px 12px;
            border-top: none;
        }
    }
}
