@use '@angular/material' as mat;

.mat-radio {
    &-medium {
        @include mat.radio-density(-1);
    }

    &-middle {
        @include mat.radio-density(-2);
    }

    &-small {
        @include mat.radio-density(-3);
    }

    &-little {
        --mdc-radio-state-layer-size: 24px;
        --mat-radio-touch-target-display: none;
    }
}
