@use '@angular/material' as mat;

.mat-table {
    &-medium {
        @include mat.table-density(-1);
    }

    &-middle {
        @include mat.table-density(-2);
    }

    &-small {
        @include mat.table-density(-3);
    }

    &-little {
        @include mat.table-density(-4);
    }

    &-bitty {
        --mat-table-header-container-height: 36px;
        --mat-table-footer-container-height: 32px;
        --mat-table-row-item-container-height: 32px;
    }
}

.mat-mdc-no-data-row {
    text-align: center;
}
