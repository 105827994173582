@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-ExtraLight.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-ExtraBold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FixelDisplay';
    src: url(~assets/fonts/FixelDisplay-Black.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}
