$blue: #004282;
$blue_dark: #01396f;
$blue_hard: #01315f;
$blue_light: #2b8dc7;
$blue_highlight: #d9e7ee;
$blue_superhighlight: #e8f1f5;
$blue_hz: #c9dbed;
$tg: #0088cc;
$blue_optional: #7d2bcf;

$purple: #a46bdd;

$indigo: #3f51b5;
$periwinkle_blue: #9994ff;
$blue_violet: #be7dff;
$link: #82b1ff;
$link_hover: #448aff;

$black: #212121;
$black_light: #454545;
$black_gray: #2c2c2c;
$black_total: #000000;

$gray: #b7b7b7;
$gray_superlight: #bdbdbd;
$gray_icon_hover: #fafafa;
$gray_light: #ecf0f1;
$gray__highlight: #f2f2f2;
$gray__pre-highlight: #a1a1a1;
$gray__dark: #7b939e;
$gray__superdark: #424242;
$gray_darken: #2c2c2c;

$red: #dd1818;
$red_alt: #ec644b;
$red_icon_hover: #ff8a80;
$red_crimson: #ff5252;
$red_icon: #f44336;
$dark_red: #45120d;
$red_light: #fb8c00;
$red_brown: #532520;

$dark_brown: #45120d;

$alert_red: #d41f1f;
$alert_green: #118b2b;
$alert_yellow: #ecb215;

$green: #2ecc71;
$mint_green: #98ff8f;
$light_green: #4caf50;
$dark_green: #2e7d32;

$white: #ffffff;
$dark_white: #e0e0e0;

$body: #d9e7ee;

$blue_uk: #00aeef;
$yellow_uk: #ffd600;
$goldenrod: #ffcb40;
$dark_orange: #fb8c00;
